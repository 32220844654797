import { defineStore } from 'pinia';
import { firestore } from "@/config/firebase.config";
import { collection, doc, getDoc, getDocs, addDoc, deleteDoc, query, where, setDoc } from "firebase/firestore";
import { ContactForm } from '@/models/contact-form.model';
import { NewsLetterSubscription } from '@/models/newsletter-subscription.model';

export const useCommsStore = defineStore('comms', {
  state: () => ({
    loading: false,
    loadingMessage: '',
  }),
  actions: {
    async postContactForm(contactForm: ContactForm) {
      this.loading = true;
      this.loadingMessage = 'Sending message...';

      await addDoc(collection(firestore, "contact-form"), contactForm);
      this.loadingMessage = '';
      this.loading = false;
      return true;
    },
    async subscribeToNewsletter(newsLetterSub: NewsLetterSubscription) {
      this.loading = true;
      this.loadingMessage = 'Subscribing...';

      await addDoc(collection(firestore, "newsletter-subscriptions"), newsLetterSub);
      this.loadingMessage = '';
      this.loading = false;
      return true;
    }
  }
});