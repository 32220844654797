
import { useUserStore } from "@/stores/userStore";
import { Options, Vue } from "vue-class-component";
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { notify } from "notiwind";
import NotifyComponent from "./components/global/NotifyComponent.vue";

@Options({
  components: {
    FooterComponent,
    NavbarComponent,
    NotifyComponent
  },
})

export default class App extends Vue {
  userStore = useUserStore();

  get showNavbar() {
    let includesAuth = this.$route.path.includes("auth");
    let includesOrganiser = this.$route.path.includes("organiser");

    return !includesAuth && !includesOrganiser;
  }

  get showFooter() {
    let includesAuth = this.$route.path.includes("auth");
    let includesOrganiser = this.$route.path.includes("organiser");

    return !includesOrganiser;
  }

  mounted() {
    this.userStore.refreshUser();
  }

  // test triggers on footer element, DELETE SOON
  triggerSuccess() {
    notify({
      group: "toast-messages",
      title: "Success",
      type: "success",
      text: "Your account was registered"
    }, 2000);
  }

  triggerWarning() {
    notify({
      group: "toast-messages",
      title: "Warning",
      type: "warning",
      text: "You have almost reached your limit."
    }, 2000);
  }

  triggerInfo() {
    notify({
      group: "toast-messages",
      title: "Info",
      type: "info",
      text: "Here's some info."
    }, 2000);
  }

  triggerDanger() {
    notify({
      group: "toast-messages",
      title: "Danger",
      type: "danger",
      text: "Don't do that!"
    }, 2000);
  }
}
