import { RouteRecordRaw } from "vue-router";

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "auth/login",
  },
  {
    path: "login",
    name: "Login",
    component: () => import("./views/LoginView.vue")
  },
  {
    path: "register",
    name: "Register",
    component: () => import("./views/RegisterView.vue")
  },
  {
    path: "onboard",
    name: "Onboard",
    component: () => import("./views/OnboardView.vue")
  },
  {
    name: "PhoneVerify",
    path: "phone-verify/:phoneNumber",
    component: () => import("./views/PhoneVerifyView.vue")
  }
];