import { RouteRecordRaw } from "vue-router";

export const organiserRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "organiser/dashboard",
  },
  {
    path: "dashboard",
    name: "OrganiserDashboard",
    component: () => import("./views/DashboardView.vue")
  },
  {
    path: "profile",
    name: "OrganiserProfile",
    component: () => import("./views/ProfileView.vue")
  },
  {
    path: "events/all",
    name: "OrganiserAllEvents",
    component: () => import("./views/events/AllEventsView.vue")
  },
  {
    path: "events/create",
    name: "OrganiserCreateEvent",
    component: () => import("./views/events/AddEventView.vue")
  },
  {
    path: "events/all/:eventId",
    name: "OrganiserEventDetails",
    component: () => import("./views/events/SingleEventView.vue")
  }
];