import { generateClasses } from "@formkit/themes";
import { genesisIcons } from "@formkit/icons"

export default {
  icons: {
    ...genesisIcons
  },
  config: {
    classes: generateClasses({
      global: {
        inner: 'relative',
        suffixIcon: 'pr-2 h-full absolute right-0 top-0 flex items-center text-gray-500 [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] [&>svg]:m-auto'
      },
      form: {
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'mb-1 text-xs text-red-500'
      },
      text: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 overflow-hidden formkit-invalid:border-red-500',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'mb-1 text-xs text-red-500'
      },
      'datetime-local': {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 overflow-hidden formkit-invalid:border-red-500',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'mb-1 text-xs text-red-500'
      },
      select: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 overflow-hidden formkit-invalid:border-red-500',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        selectIcon: `appearance-none bg-transparent flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none [&amp;>svg]:w-[1em] `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'mb-1 text-xs text-red-500'
      },
      otp: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 formkit-invalid:border-red-500 flex flex-row gap-2 items-center justify-between mx-auto w-full',
      },
      phone: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 formkit-invalid:border-red-500 flex flex-row gap-2 items-center mx-auto w-full',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'mb-1 text-xs text-red-500'
      },
      password: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 overflow-hidden formkit-invalid:border-red-500',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'mb-1 text-xs text-red-500'
      },
      tel: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 overflow-hidden focus-within:border-blaze-orange-500',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red-500 mb-1 text-xs'
      },
      textarea: {
        outer: 'mb-1',
        label: `
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        inner: 'mt-2.5 mb-1 overflow-hidden focus-within:border-blaze-orange-500',
        input: `
          block
          w-full
          rounded-md
          px-3.5 py-2
          text-gray-900 shadow-sm
          placeholder:text-gray-400
          ring-1 ring-inset ring-gray-300
          focus:ring-2 focus:ring-inset focus:ring-blaze-orange-600
          sm:text-sm sm:leading-6 outline-0 formkit-invalid:ring-red-500
        `,
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red-500 mb-1 text-xs'
      },
      submit: {
        input: "rounded-md bg-blaze-orange-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blaze-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blaze-orange-600"
      },
      radio: {
        outer: 'mb-1',
        label: 'mb-1/2',
        legend: `
          mb-2
          block
          text-sm
          formkit-invalid:text-red-500
          font-semibold leading-6 text-gray-900
        `,
        wrapper: `flex items-center mb-1 gap-x-4 `,
        inner: 'formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
        input: `appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer`,
        decorator: `block relative h-5 w-5 mr-2 rounded bg-white bg-gradient-to-b from-transparent to-gray-200 ring-1 ring-gray-400 peer-checked:ring-blaze-orange-500 text-transparent peer-checked:text-blaze-orange-500 rounded-full`,
        decoratorIcon: 'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-5 p-[5px]',
      },
      checkbox: {
        outer: '',
        input: 'sr-only peer',
        decoratorIcon: `hidden`,
        wrapper: 'relative inline-flex items-center cursor-pointer',
        label: 'ml-3 text-sm font-medium text-gray-900 dark:text-gray-300',
        decorator: `block w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blaze-orange-300 dark:peer-focus:ring-blaze-orange-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blaze-orange-600`
      }
    })
  }
}