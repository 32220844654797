export const ASPECT_RATIOS = {
  SQUARE: 1/1,
  PORTRAIT: 4/6,
  STANDARD: 4/3,
  LANDSCAPE: 3/2,
  FULL_PORTRAIT: 9/16,

  WIDE: 16/9,
  ULTRA_WIDE: 21/9,
  CINEMA: 21/10,
  CINEMA_WIDE: 64/27,
  CINEMA_ULTRA_WIDE: 43/18,
  CINEMA_SUPER_ULTRA_WIDE: 32/9,
  CINEMA_SUPER_ULTRA_WIDE_2: 32/9
}