import App from "./App.vue";
import { createApp } from "vue";
import "./registerServiceWorker";
import Notifications from "notiwind";

// import store from "./store";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import config from '../formkit.config'
import { plugin, defaultConfig, createInput } from '@formkit/vue';

import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';

/**
 * Import main css file
 */
import "./styles/main.scss";
import SpinnerComponent from "./components/global/SpinnerComponent.vue";
import OTPInputComponent from "./components/global/OTPInputComponent.vue";
import PhoneInputComponent from "./components/global/PhoneInputComponent.vue";

import RichTextEditor from './components/global/RichTextComponent.vue';
import ModalComponent from './components/global/ModalComponent.vue';
import ImageCropperComponent from './components/global/ImageCropperComponent.vue';


createApp(App)
  .use(router)
  .use(createPinia().use(piniaPluginPersistedstate))
  .use(plugin, defaultConfig({
    ...config,
    inputs: {
      "otp": createInput(OTPInputComponent, {
        props: ['digits', 'separator']
      }),
      "phone": createInput(PhoneInputComponent)
    }
  }))
  .use(Notifications)
  .component('image-cropper', ImageCropperComponent)
  .component('modal', ModalComponent)
  .component('rich-text-editor', RichTextEditor)
  .component('vue-date-picker', VueDatePicker)
  .component('spinner', SpinnerComponent)
  .mount("#app");
