import { defineStore } from 'pinia';
import { useUserStore } from './userStore';

import { Event } from '@/models/event/Event';
import { firestore } from "@/config/firebase.config";
import { collection, doc, getDoc, getDocs, addDoc, deleteDoc, query, where, setDoc } from "firebase/firestore";

export const useEventStore = defineStore('event', {
  state: () => ({
    loading: false,
    loadingMessage: '',
    events: [] as Event[],
    myEvents: [] as Event[],
  }),
  getters: {
    getEvents(): Event[] {
      return this.events;
    },
    getMyEvents(): Event[] {
      return this.myEvents;
    }
  },
  actions: {
    async createEvent(event: Event) {
      this.loading = true;
      this.loadingMessage = 'Creating event...';

      const colRef = collection(firestore, "events");
      const docRef = await addDoc(colRef, event);
      this.loadingMessage = '';
      this.loading = false;
      
      return docRef.id;
    },
    async updateEvent(event: Event) {
      this.loading = true;
      this.loadingMessage = 'Updating event...';

      const eventDoc = doc(collection(firestore, "events"), event.id);
      const updatedEvent = await setDoc(eventDoc, event, { merge: true });
      this.loadingMessage = '';
      this.loading = false;
      return true;
    },
    async deleteEvent(eventId: string) {
      this.loading = true;
      this.loadingMessage = 'Deleting ...';

      const eventDoc = doc(collection(firestore, "events"), eventId);
      const deletedEvent = await deleteDoc(eventDoc);
      this.loadingMessage = '';
      this.loading = false;
      return true;
    },
    async fetchMyEvents() {
      const userStore = useUserStore();

      this.myEvents = [];

      const q = query(collection(firestore, "events"), where("createdBy.id", "==", userStore.user?.id));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        this.myEvents.push({
          id: doc.id,
          ...doc.data()
        } as Event);
      });
    },
    async fetchAllEvents() {
      this.loading = true;
      this.loadingMessage = "Fetching events..." 
      this.events = [];

      const q = query(collection(firestore, "events"));
      const querySnapshot = await getDocs(q);

      this.loading = false;
      this.loadingMessage = '';

      querySnapshot.forEach((doc) => {
        this.events.push({
          id: doc.id,
          ...doc.data()
        } as Event);
      });
    },
    async fetchSingleEvent(eventId: string) {

      this.loading = true;
      this.loadingMessage = "Fetching event...";

      const eventDoc = doc(collection(firestore, "events"), eventId);
      const eventSnap = await getDoc(eventDoc);

      this.loading = false;
      this.loadingMessage = ""
      if (eventSnap.exists()) {
        return {
          id: eventSnap.id,
          ...eventSnap.data()
        } as Event;
      } else {
        return null;
      }
    }
  },
  persist: true
});


