import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, RouteLocation, NavigationGuardNext } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { authRoutes } from "@/modules/auth/routes";
import { organiserRoutes } from "@/modules/organiser/routes";
import { useUserStore } from "@/stores/userStore";
import { ref } from "vue";

// const userStore = useUserStore();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/why",
    name: "Why",
    component: () => import(/* webpackChunkName: "why" */ "../views/WhyView.vue"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import(/* webpackChunkName: "welcome" */ "../views/WelcomeView.vue"),
  },
  {
    path: "/event/:eventId",
    name: "Event",
    component: () => import(/* webpackChunkName: "event" */ "../views/EventView.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "test" */ "../views/TestView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    name: "Privacy",
    path: "/privacy-policy",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import(/* webpackChunkName: "auth" */ "../modules/auth/AuthModule.vue"),
    children: [ ...authRoutes ]
  },
  {
    path: "/organiser",
    name: "Organiser",
    component: () => import(/* webpackChunkName: "organiser" */ "../modules/organiser/OrganiserModule.vue"),
    children: [ ...organiserRoutes ],
    meta: {
      requiresAuth: true,
      requiresOrganiserRole: true,
      requiresVerifiedOrganiser: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "not-found" */ "../views/NotFoundView.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition ? savedPosition : {top: 0}
  }
});

const authorisationGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  const userStore = useUserStore();
  
  // Check for authentication requirement
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLoggedIn = ref(userStore.userAuth);
    if (!isLoggedIn.value) {
      next({
        name: "Login",
        query: { redirect: to.fullPath }
      });
      return;  // Halt the guard if the user is not logged in
    }
  }

  // Check for organiser role requirement
  if (to.matched.some(record => record.meta.requiresOrganiserRole)) {
    const isOrganiser = ref(userStore.user?.isEventOrganiser);
    if (!isOrganiser.value) {
      next({
        name: "Home",
        query: { redirect: to.fullPath }
      });
      return;  // Halt the guard if the user is not an organiser
    }
  }

  // Check for verified organiser requirement
  if (to.matched.some(record => record.meta.requiresVerifiedOrganiser)) {
    const isVerified = ref(userStore.user?.isVerifiedOrganiser);
    if (!isVerified.value) {
      next({
        name: "Welcome",
        query: { redirect: to.fullPath }
      });
      return;  // Halt the guard if the user is not a verified organiser
    }
  }

  next(); // Allow the user to continue to the route
}

/**
 * Before Each
 */
router.beforeEach((to, from, next) => {
  authorisationGuard(to, from, next);
});

export default router;
