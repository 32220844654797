import { format } from 'date-fns';
import {
  differenceInDays,
  differenceInHours,
  differenceInYears,
  differenceInMonths,
  differenceInMinutes } from 'date-fns';

const getDay = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "dd");
};

const getMonth = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "MMM");
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "dd MMM yyyy - h:mm a");
};

const getDetailedDifference = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();

  const years = differenceInYears(date, now);
  const months = differenceInMonths(date, now) % 12;  // modulo to get remaining months after years
  const days = differenceInDays(date, now) % 30;  // assuming an average month has 30 days
  const hours = differenceInHours(date, now) % 24;  // modulo to get remaining hours after days
  const minutes = differenceInMinutes(date, now) % 60;  // modulo to get remaining minutes after hours

  const formattedDifference = `
    ${years > 0 ? `${years} year${years > 1 ? 's' : ''}, ` : ''}
    ${months > 0 ? `${months} month${months > 1 ? 's' : ''}, ` : ''}
    ${days > 0 ? `${days} day${days > 1 ? 's' : ''}, ` : ''}
    ${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}, ` : ''}
    ${minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : ''}
  `.trim();

  return formattedDifference;
};

export {
  getDay,
  getMonth,
  formatDate,
  getDetailedDifference
};