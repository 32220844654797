
import { Options, Vue } from "vue-class-component";
import { useCommsStore } from "@/stores/commsStore";
import { NewsLetterSubscription } from "@/models/newsletter-subscription.model";

@Options({
  name: "FooterComponent",
})

export default class FooterComponent extends Vue {
  commsStore = useCommsStore();

  navigation = {
    events: [
      { name: "Browse Events", link: "/" },
      { name: "Create Events", link: "/organiser/events/create" },
      // { name: "Sell Tickets", link: "/sell-tickets" },
    ],
    legal: [
      { name: "Terms", link: "/terms" },
      { name: "Privacy", link: "/privacy-policy" },
    ]
  };

  async submitHandler(formModel: NewsLetterSubscription) {
    formModel.source = 'landing-site-footer';
    formModel.daySubscribed = new Date().toISOString();
    const contactFormRef = await this.commsStore.subscribeToNewsletter(formModel)

    if (contactFormRef) {
      console.log(contactFormRef);
    }
  }
}
