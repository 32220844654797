import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotifyComponent = _resolveComponent("NotifyComponent")!
  const _component_NavbarComponent = _resolveComponent("NavbarComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NotifyComponent),
    (_ctx.showNavbar)
      ? (_openBlock(), _createBlock(_component_NavbarComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_FooterComponent, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}