import { defineStore } from 'pinia';
import { User } from '@/models/user/User';
import { auth, firestore } from "@/config/firebase.config";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

export const useUserStore = defineStore('user', {
  state: () => ({
    loading: false,
    loadingMessage: "",
    userAuth: null as any,
    user: null as User | null
  }),
  getters: {
    fetchUser(): User | null {
      return this.user;
    }
  },
  actions: {
    refreshUser() {
      this.loading = true;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.userAuth = user;
          const { uid } = user;
          
          const userDoc = doc(collection(firestore, "users"), uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            this.user = {
              id: userSnap.id,
              ...userSnap.data(),
            } as User;

            this.loading = false;
          } else {
            this.user = null;
            this.loading = false;
          }
        } else {
          this.user = null;
          this.userAuth = null;
          this.loading = false;
        }
      });
    },
    async updateUser(user: User) {
      this.loading = true;
      this.loadingMessage = "Updating..."
      const userDoc = doc(collection(firestore, "users"), user.id);
      const updatedUser = await setDoc(userDoc, user, { merge: true });
      
      this.loading = false;
      this.loadingMessage = "";
    },
    async setUser(uid: string) {
      this.loading = true;
      this.loadingMessage = "Loading..."
      const userDoc = doc(collection(firestore, "users"), uid);
      const userSnap = await getDoc(userDoc);
      if (userSnap.exists()) {
        this.user = {
          id: userSnap.id,
          ...userSnap.data(),
        } as User;

        this.loading = false;
        this.loadingMessage = "";
      } else {
        this.user = null;
        this.loading = false;
        this.loadingMessage = "";
      }
    },
    logout() {
      this.loading = true;
      this.loadingMessage = "Logging out...";
      auth.signOut().then(() => {
        this.user = null;
        this.userAuth = null;
        this.loading = false;
        this.loadingMessage = "";
      }).catch((error) => {
        this.loading = false;
        this.loadingMessage = "";
      });
    }
  },
  persist: true
});